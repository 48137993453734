$(document).ready(function() {
    var accordionIds = [];
    $('.eael-accordion-header').each(function() {
        var id = $(this).attr('id');
        if (id) {
            accordionIds.push(id);
        }
    });

    $('.fa-toggle').hide();

    $('.eael-accordion-header').each(function() {
        if ($(this).find('.toggle-btn').length === 0) {
            var isActive = $(this).hasClass('active');
            var buttonText = isActive ? 'Less' : 'More';
            $(this).append('<button class="toggle-btn italic text-white underline font-light">' + buttonText + '</button>');
        }
    });

    function updateButtonText($header) {
        var $button = $header.find('.toggle-btn');

        if ($header.hasClass('active')) {
            $button.text('Less...');
        } else {
            $button.text('More...');
        }
    }

    function resetAllButtons() {
        $('.eael-accordion-header').each(function() {
            var $button = $(this).find('.toggle-btn');
            $button.text('More');
        });
    }

    $(document).on('click', '.eael-accordion-header', function() {
        var $header = $(this);

       
        setTimeout(function() {
            resetAllButtons();
            updateButtonText($header);
        }, 300); 
    });

    $('.eael-accordion-header').each(function() {
        updateButtonText($(this));
    }); 

    $('.eael-accordion-content').each(function() {
        var $content = $(this);
        if ($content.is(':visible')) {
            $content.prev('.eael-accordion-header').addClass('active');
            $(document).trigger('ea-advanced-accordion-triggered', [$content]);
        }
    });
});
