$(document).ready(function() {
    function addHamburgerMenuEvents() {
        $('.hamburger').off('click').on('click', function() {
            $(this).toggleClass('is-active');
            toggleMenu();
        });

        $('#menu-main-menu .menu-item a:lt(4)').off('click').on('click', function() {
            $('.hamburger').toggleClass('is-active');
            toggleMenu();
        });
    }

    function toggleMenu() {
        var nav = $('#site-navigation');
        var totalItems = $('#menu-main-menu .menu-item').length;
        var maxHeight = 0;

        if (nav.hasClass('hidden')) {
            
            nav.removeClass('hidden').addClass('active');
            $('#menu-main-menu .menu-item').each(function(index) {
                var $item = $(this);
                maxHeight += $item.outerHeight(true);
                setTimeout(function() {
                    nav.css('max-height', maxHeight + 'px'); 
                    $item.addClass('slide-down').removeClass('slide-up');
                }, index * 150); 
            });
        } else {
            
            $($('#menu-main-menu .menu-item').get().reverse()).each(function(index) {
                var $item = $(this);
                maxHeight -= $item.outerHeight(true);
                setTimeout(function() {
                    $item.addClass('slide-up').removeClass('slide-down');
                    if (index === totalItems - 1) {
                        setTimeout(function() {
                            nav.removeClass('active');
                            nav.addClass('hidden');
                        }, 150);
                        nav.css('max-height', '0px'); 
                    }
                }, index * 150);
            });
        }
    }

    function checkScreenWidth() {
        if ($(window).width() < 992) {
            addHamburgerMenuEvents();
        } else {
            $('.hamburger').off('click');
            $('#menu-main-menu .menu-item a:lt(4)').off('click');
            $('#site-navigation').css('max-height', 'none');
        }
    }

    checkScreenWidth();
    $(window).resize(checkScreenWidth);
});
