import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();


function initializeSal() {
  if (window.innerWidth >= 993) { // Set your desired breakpoint here
      AOS.init();
  }
}

document.addEventListener('DOMContentLoaded', initializeSal); 


  if (window.innerWidth >= 993) {
     AOS.init();
  } else {
      AOS.init({
        disable: true
      })
  }
