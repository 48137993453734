$(document).ready(function() {
    var secondaryLogo = $('#scroll-logo');
    var logoDesktop = $('#scroll-logo-desktop');
    var homepageFlag = $('.homepage-flag');

    var scrollThreshold = 300;
    var scrollThresholdDesktop = 500;

    $(window).scroll(function() {
        if ($(window).scrollTop() > scrollThreshold || $(window).scrollTop() > scrollThresholdDesktop) {
            secondaryLogo.addClass('show');
            logoDesktop.addClass('show');
            homepageFlag.addClass('hide');
        } else {
            secondaryLogo.removeClass('show');
            logoDesktop.removeClass('show');
            homepageFlag.removeClass('hide');
        }
    });

    if ($(window).scrollTop() > scrollThreshold || $(window).scrollTop() > scrollThresholdDesktop) {
        secondaryLogo.addClass('show');
        logoDesktop.addClass('show');
        homepageFlag.addClass('hide');
    } else {
        secondaryLogo.removeClass('show')
        logoDesktop.removeClass('show');
        homepageFlag.removeClass('hide');
    }
});
