$(window).on("load", function () {
    if ($('body').hasClass('home')) {    
        
    setupScrollingAndHighlighting();
    
    } else { 
        $('a[aria-current]').addClass('active');
    }
});


function setupScrollingAndHighlighting() {
    // Smooth scrolling

    setTimeout(function() {
        $("a[data-target]").click(function (e) {
            if ($(this).attr('data-no-smooth-scroll')) {
                return true; 
            }

            e.preventDefault();
    
            var target = $(this).attr("data-target");         
            var targetElement = $(target);
            var navbarHeight = $(".navbar").outerHeight();
    
            // Get the current width of the window
            var screenWidth = $(window).width();
    
            // Dynamically calculate the scroll offset
            var scrollOffset = calculateScrollOffset(screenWidth);
    
            // Calculate the final scroll position
            var scrollToPosition = targetElement.offset().top + navbarHeight - scrollOffset;
    
            // Animate the scroll
            $("html, body").animate({
                scrollTop: scrollToPosition
            }, 1000);
    
            return false;
        });
    }, 1000);


   

    highlightCurrentMenuItem();
    $(window).on("scroll", highlightCurrentMenuItem);

    function highlightCurrentMenuItem() {
        var scrollTop = $(window).scrollTop() + $(".navbar").outerHeight();  
        var activeFound = false;
        
        $('a[data-target]').each(function () {
            var target = $(this).attr('data-target');
            var targetElement = $(target);
            var elementTop = targetElement.offset().top;
            var elementBottom = elementTop + targetElement.outerHeight();

        
            if (elementTop - 100 <= scrollTop && scrollTop < elementBottom ) {
                
                $('a[data-target]').removeClass('active');
                $(this).addClass('active');
                activeFound = true;
                return false; 
            }
        });
        
        if (!activeFound) {
            $('a[data-target]').removeClass('active');
        }
    }
}

function calculateScrollOffset(screenWidth) {
    // Set bounds for screen width
    let minScreen = 450;
    let maxScreen = 1200;

    // Set bounds for scroll offsets
    let minOffset = 230;  // Offset for smallest screen
    let maxOffset = 210;  // Offset for largest screen

    if (screenWidth <= minScreen) {
        return minOffset;
    }
    if (screenWidth >= maxScreen) {
        return maxOffset;
    }

    // Calculate interpolation
    let slope = (maxOffset - minOffset) / (maxScreen - minScreen);
    let offset = slope * (screenWidth - minScreen) + minOffset;

    return offset;
}

